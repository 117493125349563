@charset "UTF-8";

@font-face {
  font-family: "untitled-font-6";
  src:url("fonts/untitled-font-6.eot");
  src:url("fonts/untitled-font-6.eot?#iefix") format("embedded-opentype"),
    url("fonts/untitled-font-6.woff") format("woff"),
    url("fonts/untitled-font-6.ttf") format("truetype"),
    url("fonts/untitled-font-6.svg#untitled-font-6") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "untitled-font-6" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-6" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-brush:before {
  content: "\61";
}
.icon-chevron-down-passive:before {
  content: "\62";
}
.icon-compare:before {
  content: "\63";
}
.icon-copy1:before {
  content: "\64";
}
.icon-copy2:before {
  content: "\65";
}
.icon-cross:before {
  content: "\66";
}
.icon-delete:before {
  content: "\67";
}
.icon-eye:before {
  content: "\68";
}
.icon-indicators:before {
  content: "\69";
}
.icon-left-arrow:before {
  content: "\6a";
}
.icon-line1:before {
  content: "\6b";
}
.icon-line2-left:before {
  content: "\6c";
}
.icon-line2-right:before {
  content: "\6d";
}
.icon-lock:before {
  content: "\6e";
}
.icon-long-position:before {
  content: "\6f";
}
.icon-pencil:before {
  content: "\70";
}
.icon-pitchfork:before {
  content: "\71";
}
.icon-settings:before {
  content: "\72";
}
.icon-text:before {
  content: "\73";
}
.icon-trade:before {
  content: "\74";
}
.icon-xabcd-pattern:before {
  content: "\75";
}
